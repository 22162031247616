/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { OBJECT_STATE } from "../../types/IrisObject";
import getApiData from "../../lib/getApiData";
import LoadingIcon from "../../components/loadingIcon";
import { AuthenticationState } from "../../context/AuthenticationContext";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../context/SubmissionContext";
import Layout from "../../components/layout";
import { validateToken } from "../../lib/functions";

export default function SubmitStartPage() {
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";
  const authenticationState = useContext(AuthenticationState) || {
    token: "",
    isAuthenticated: false,
    username: "",
  };

  const { isAuthenticated, token, username } = authenticationState;

  const [objectId, setObjectId] = useState("");
  const [objectMetadata, setObjectMetadata] = useState("");
  const [error, setError] = useState(false);

  const currentTime = new Date()
    .toISOString()
    .replace(/T/, " ")
    .replace(/\..+/, "");

  const defaultObjectMetadata = {
    state: OBJECT_STATE.DRAFT,
    label: "IRIS object submitted by workflow",
    ownerId: username,
    createdAt: currentTime,
    lastModifiedAt: currentTime,
    useEmbargoDate: false,
    embargoDate: "",
  };

  /**
   * Get unique PK when starting creating a new material
   */
  useEffect(() => {
    (async () => {
      if (isAuthenticated && token) {
        const existingObjectId = "";
        const existingObjectMetadata = defaultObjectMetadata;

        // Reset the currect state as some older information might still be in it
        submissionDispatch({
          type: "RESET_STATE",
        });

        setObjectId(existingObjectId);
        setObjectMetadata(existingObjectMetadata);

        // Clear localStorage
        localStorage.removeItem("UPPY_successful_uploaded");

        if (!existingObjectId) {
          /**
           * Send a request to get the object UUID (PK)
           */
          await getApiData({
            endpoint: "object/metadata",
            method: "post",
            params: JSON.stringify({ objectMetadata: existingObjectMetadata }),
            headers: {
              "Content-Type": "application/json",
              "X-Amz-Security-Token": token,
            },
            debug: false,
          })
            .then((response) => {
              // Debug only:
              // throw new Error("oh no");

              if (response.data.metadata.PK) {
                // Save the new object ID frpm the API
                setObjectId(response.data.metadata.PK);
              } else {
                throw new Error("No PK returned.");
              }
            })
            .catch((error) => {
              console.error("🚀 ~ error", error);
              setError(true);
            });
        }
      } else {
        navigate("/login");
      }
    })();
  }, []);

  /**
   * Once we have a new unique objectId (PK) we store it in the `submissionContext` and redirect the user to step 0
   */
  useEffect(() => {
    if (objectId && objectMetadata) {
      // Save received PK in the submission state
      submissionDispatch({
        type: "UPDATE_STATE",
        objectId,
        objectMetadata,
      });

      // Navigate to step 0
      navigate(`/submit/${objectId}/0`);
    }
  }, [objectId, objectMetadata]);

  return (
    <Layout>
      <div className="text-center">
        {error ? (
          <>
            Sorry we encountered an error, please{" "}
            <a href="/submit/new/">try again</a> or contact the admin.
          </>
        ) : (
          <>
            <LoadingIcon />
            <br /> Loading your new material, please wait.
          </>
        )}
      </div>
    </Layout>
  );
}
